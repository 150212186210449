import Vue from 'vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import vuetify from './plugins/vuetify';

import './assets/slyle/global.scss';

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_TAG_ID },
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
