<template>
  <section class="header-background">
    <v-img height="650" src="/header-bg.webp">
      <div class="title-wrap">
        <div class="section-title" ref="sloganAnimate"/>
        </div>
    </v-img>
  </section>
</template>

<script>
import animate from '../helpers/animate';

export default {
  name: 'header-background',
  data() {
    return {
      sloganWords: ['Dev-Team.Club', 'Code', 'Learn', 'Enjoy'],
    };
  },
  mounted() {
    this.sloganAnimate();
  },
  methods: {
    sloganAnimate() {
      let timeout = 0;
      const delay = 7000;
      this.sloganWords.forEach((item, i) => {
        const slogan = this.$refs.sloganAnimate;
        setTimeout(() => {
          animate(item, slogan);
          if (i >= this.sloganWords.length - 1) {
            setTimeout(() => {
              this.sloganAnimate();
            }, delay);
          }
        }, timeout);
        timeout += delay;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-background {
    width: 100%;
    margin-top: -80px;
    position: relative;
    .title-wrap {
        top: 50%;
        left: 0;
        width: 100%;
        text-align: center;
        transform: translate(0, -50%);
        position: absolute;
        font-weight: 700;
        text-transform: uppercase;
        color: white;
        .section-title {
            font-size: 86px;
            line-height: 100px;
            text-align: center;
            &::v-deep span {
              width: 5rem;
              display: inline-block;
              text-transform: uppercase;
            }
            @media screen and (max-width: 600px) {
                font-size: 32px;
                line-height: 60px;
                &::v-deep span {
                  width: 1.5rem;
              }
            }
        }
    }
}
</style>
