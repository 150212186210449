<template>
  <section id="work" class="projects">
    <h2 class="section-title">НАШИ ПОСЛЕДНИЕ / РАБОТЫ</h2>
    <v-container>
      <v-row>
        <v-col
          v-for="(project, index) in projects"
          :key="index"
          cols="12"
          sm="12"
          md="6"
          lg="4"
          class="project"
        >
          <!-- <a
            v-ga="$gtag.commands.projects.bind($parent, project.name)"
            :href="project.link"
            target="_blank"
            class="link-row"
          > -->
          <a
            :href="project.link"
            target="_blank"
            rel="noreferrer"
            class="link-row"
          >

            <img
              width="100%"
              :src="project.previewUrl"
              :alt="project.name"
              class="photo"
            />
            <div class="meta">
              <p class="name">{{ project.name }}</p>
              <div class="action">
                <p class="description">
                  {{ project.description }}
                </p>
                <p class="view-link text-none">
                  Перейти на сайт
                  <v-icon class="link-icon">mdi-arrow-right</v-icon>
                </p>
              </div>
            </div>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'projects',
  data() {
    return {
      projects: [
        {
          name: 'CombatSport',
          link: 'https://combat-sport.club/',
          previewUrl: '/projects/combatsport.webp',
          description:
          'CombatSport - это международная платформа, для организации соревнований и чемпионатов по боевым видам искусств',
        },
        {
          name: 'Aatlas',
          link: 'https://www.aatlas.com/',
          previewUrl: '/projects/aatlas.webp',
          description:
          'Aatlas — это самый простой и полезный способ создавать, исследовать и делиться историей жизни',
        },
        {
          name: 'BreakKonnect',
          link: 'https://breakkonnect.com/',
          previewUrl: '/projects/breakkonnect.webp',
          description:
          'Всемирное сообщество танцоров с уникальными возможностями для организации баттлов по брейк-дансу. Функции предварительной оплаты и потокового видео',
        },
        {
          name: 'Plush',
          link: 'https://plush.family/',
          previewUrl: '/projects/plush.webp',
          description:
          'Plush — это инновационная экосистема, призванная помочь семьям преодолевать трудности, делать лучший выбор, расти и достигать большего вместе.',
        },
        {
          name: 'AntHive',
          link: 'https://anthive.io/',
          previewUrl: '/projects/anthive.webp',
          description:
          'Проверьте свои навыки в различных языках программирования, бросьте вызов разработчикам со всего мира. Рейтинг и таблица лидеров в реальном времени.',
        },
        {
          name: 'OnDemand Fitness',
          link: 'https://ondemandfitness.app/',
          previewUrl: '/projects/ondemandfitness.webp',
          description:
          'OnDemand Fitness - мобильное приложение предоставляющее доступ к тысячам упражнений, которые выбираются случайным образом в зависимости от ваших настроек и доступного оборудования.',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.projects {
  padding: 70px 0;
  .section-title {
        font-size: 83px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 30px;
        width: 100%;
        @media screen and (max-width: 790px) {
          position: relative;
          font-size: 32px;
        }
        @media screen and (max-width: 600px){
          text-align: center;
          position: relative;
          margin-top: 0;
        }
    }
  .project {
    position: relative;
    &:hover .photo {
      transform: scale(1.1);
      opacity: 0.3;
    }
    &:hover .action {
      opacity: 1;
    }
    &:hover .meta {
      top: 20px;
      @media (max-width: 480px) {
        top: 0;
      }
      @media (min-width: 1264px) and (max-width: 1900px) {
        top: 0;
      }
    }
    .link-row {
      height: 100%;
      color: white;
      text-decoration: none;
      background-color: black;
      display: block;
      overflow: hidden;
      position: relative;
    }
    .photo {
      transition: all ease .5s;
      margin-bottom: -8px;
      height: 100%;
    }
    .meta {
      position: absolute;
      top: 80%;
      left: 0;
      text-align: left;
      padding: 0 40px;
      transition: all ease .5s;
      max-width: 65%;
      min-height: 203px;
      @media (min-width: 1264px) and (max-width: 1900px) {
        top: 75%;
        max-width: 100%;
      }
      @media (max-width: 480px) {
        max-width: 100%;
        padding: 0 20px;
        top: 70%;
      }
    }
    .name {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
      text-shadow: 0 0 2px #000000;
      @media (min-width: 1264px) and (max-width: 1900px) {
        font-size: 30px;
      }
    }
    .action {
      opacity: 0;
      transition: all ease .5s;
    }
    .view-link {
      font-size: 16px;
      letter-spacing: 0px;
      font-weight: 600;
      color: #CB2A76;
      display: inline;
      .link-icon {
        color: inherit;
      }
      &:hover .link-icon {
        margin-left: 12px;
      }
    }
    .description {
      @media (max-width: 480px) {
        font-size: 15px;
      }
    .link-icon {
      color: #CB2A76;
      margin-left: 5px;
    }
    }
  }
}
</style>
