<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      app
      dark
      color="black"
      class="mobile-menu"
    >
      <v-list dense>
        <v-list-item @click.stop="navBtnHandler('#team')">
          <v-list-item-content>
            <v-list-item-title class="menu-item-name">
              Команда
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.stop="navBtnHandler('#work')">
          <v-list-item-content>
            <v-list-item-title class="menu-item-name">
              Проекты
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
         <v-list-item @click.stop="navBtnHandler('#job')">
          <v-list-item-content>
            <v-list-item-title class="menu-item-name">
              Связаться с нами
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      fixed
      elevation="0"
      app
      hide-on-scroll
      :class="classHeader"
      class="menu"
      height="80"
    >
      <v-btn
        icon
        class="d-sm-none"
        :class="classHeader"
        @click.stop="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <a
        href="/"
        class="d-flex justify-center align-end logo"
      >
        dev / team
      </a>
      <v-spacer />
      <div class="justify-end align-end d-none d-sm-flex">
        <v-btn
          class="text-none nav-link"
          :class="classHeader"
          text
          tile
          @click.stop="navBtnHandler('#team')"
        >
          Команда
        </v-btn>
        <v-btn
          class="text-none nav-link"
          :class="classHeader"
          text
          tile
          @click.stop="navBtnHandler('#work')"
        >
          Проекты
        </v-btn>
        <v-btn
          class="text-none nav-link"
          :class="classHeader"
          text
          tile
          @click.stop="navBtnHandler('#job')"
        >
          Связаться с нами
        </v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <header-background />
      <team />
      <projects />
      <contact />
    </v-main>
    <v-footer dark class="black">
      <span class="mx-auto">
        &copy; {{ new Date().getFullYear() }} dev-team
      </span>
    </v-footer>
  </v-app>
</template>

<script>
import HeaderBackground from './components/HeaderBackground.vue';
import Team from './components/Team.vue';
import Projects from './components/Projects.vue';
import Contact from './components/Contact.vue';

export default {
  name: 'App',
  components: {
    HeaderBackground,
    Team,
    Projects,
    Contact,
  },
  data() {
    return {
      drawer: false,
      fixed: false,
      classHeader: null,
    };
  },
  mounted() {
    this.handlerChangeHeader();
    document.addEventListener('scroll', this.handlerChangeHeader.bind(this));
  },
  methods: {
    handlerChangeHeader() {
      this.scrolled = window.pageYOffset;
      if (this.scrolled < 50) {
        this.classHeader = 'up-page color-white';
      } else {
        this.classHeader = '';
      }
    },
    navBtnHandler(path) {
      this.$vuetify.goTo(path);
      const gaValue = path[1].toUpperCase() + path.slice(2);
      this.$gtag.event('event', { value: `${gaValue}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.color-white {
  color: white !important;
  .logo {
    color: white;
    border-color: white;
  }
}
.up-page {
  background: transparent !important;
}
.logo {
  border: 2px solid black;
  padding: 10px 18px;
  font-weight: 600;
  font-size: 16px;
  color: black;
  text-decoration: none;
  .local-switcher, .nav-link {
    margin: 10px 5px;
    font-size: 16px;
  }
  .menu {
    padding: 0 24px;
    @media (max-width: 400px) {
      padding: 0;
    }
  }
  .menu-item-name {
    font-size: 25px!important;
  }
}
</style>
