<template>
  <section id="team" class="team">
    <v-container>
      <v-row>
          <h2 class="section-title">Наша / команда</h2>
        <v-col cols="12" sm="12" md="12">
          <v-row>
            <v-col
              v-for="(member, index) in members"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="text-center"
            >
              <v-avatar size="175" class="avatar">
                <v-img :src="member.avatarUrl" :alt="member.name" />
              </v-avatar>
              <p class="member-name">
                {{ member.name }}
              </p>
              <p class="member-position">
                {{ member.position }}
              </p>
              <div v-if="member.socials" class="member-socials">
                <v-btn
                  v-for="(social, i) in member.socials"
                  :key="i"
                  :href="social.link"
                  target="_blank"
                  rel="noreferrer"
                  class="social-btn"
                  color="#878B8F"
                  icon
                >
                  <v-icon class="social-icon">
                    {{ social.icon }}
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="text-center">
              <v-avatar size="178" class="avatar">
                <v-img src="/team/blank.webp" alt="hiring" />
              </v-avatar>
              <p class="member-name">
                Будущий сотрудник
              </p>
              <p class="member-position">
                Инженер
              </p>
              <div class="member-socials">
                <v-btn
                  rel="noreferrer"
                  class="social-btn text-none mt-2"
                  color="#CB2A76"
                  outlined
                  rounded
                  @click="hiringBtnHandler"
                >
                  Присоединиться
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'team',
  data() {
    return {
      members: [
        {
          name: 'Виталий',
          position: 'Teamlead',
          avatarUrl: '/team/vitaly.webp',
          socials: [
            {
              link: 'https://www.linkedin.com/in/vitaly-kezlya-450192b/',
              icon: 'mdi-linkedin',
            },
            {
              link: 'https://www.youtube.com/channel/UCtI9EdFlDo-4m98-m5SCbcw',
              icon: 'mdi-youtube',
            },
            {
              link: 'https://www.twitch.tv/anthiveio',
              icon: 'mdi-twitch',
            },
          ],
        },
        {
          name: 'Кирилл',
          position: 'Techlead',
          avatarUrl: '/team/kirill.webp',
          socials: [
            {
              link: 'https://www.linkedin.com/in/klimonov/',
              icon: 'mdi-linkedin',
            },
            {
              link: 'https://github.com/klimonov/',
              icon: 'mdi-github',
            },
          ],
        },
        {
          name: 'Егор',
          position: 'Full-stack разработчик',
          avatarUrl: '/team/egor.webp',
          socials: [
            {
              link: 'https://github.com/egoriusRus/',
              icon: 'mdi-github',
            },
            {
              link: 'https://www.youtube.com/channel/UC5UES3woETjQDqt6pnXCSRQ',
              icon: 'mdi-youtube',
            },
          ],
        },
        {
          name: 'Макс',
          position: 'QA инженер',
          avatarUrl: '/team/maksim.webp',
          socials: [
            {
              link: 'https://github.com/Mad-Moks',
              icon: 'mdi-github',
            },
          ],
        },
        {
          name: 'Игорь',
          position: 'Back-end разработчик',
          avatarUrl: '/team/igor.webp',
          socials: [
            {
              link: 'https://github.com/Dez113',
              icon: 'mdi-github',
            },
          ],
        },
        {
          name: 'Илья',
          position: 'Front-end разработчик',
          avatarUrl: '/team/ilya.webp',
          socials: [
            {
              link: 'https://github.com/sadiolem',
              icon: 'mdi-github',
            },
          ],
        },
        {
          name: 'Дмитрий',
          position: 'Back-end разработчик',
          avatarUrl: '/team/dimadd.webp',
          socials: [
            {
              link: 'https://github.com/sw0ps',
              icon: 'mdi-github',
            },
          ],
        },
        {
          name: 'Александр',
          position: 'Front-end разработчик',
          avatarUrl: '/team/sasha.webp',
          socials: [
            {
              link: 'https://www.linkedin.com/in/sasha-astanin-4b230a200/',
              icon: 'mdi-linkedin',
            },
            {
              link: 'https://github.com/tastytea-dev',
              icon: 'mdi-github',
            },
          ],
        },
        {
          name: 'Даниил',
          position: 'Дизайнер',
          avatarUrl: '/team/daniil.webp',
          socials: [
            {
              link: 'https://vk.com/daniilzemtsov',
              icon: 'mdi-account-circle',
            },
          ],
        },
        {
          name: 'Иван',
          position: 'Front-end разработчик',
          avatarUrl: '/team/ivan.webp',
          socials: [
            {
              link: 'https://github.com/ivansheshin',
              icon: 'mdi-github',
            },
          ],
        },
        {
          name: 'Карина',
          position: 'QA инженер',
          avatarUrl: '/team/karina.webp',
          socials: [
            {
              link: 'https://github.com/idun111',
              icon: 'mdi-github',
            },
            {
              link: 'https://www.instagram.com/_drozh___/',
              icon: 'mdi-instagram',
            },
          ],
        },
        {
          name: 'Виктор',
          position: 'Front-end разработчик',
          avatarUrl: '/team/victor.webp',
          socials: [
            {
              link: 'https://github.com/VictorNov',
              icon: 'mdi-github',
            },
            {
              link: 'https://www.instagram.com/victor_nov/',
              icon: 'mdi-instagram',
            },
          ],
        },
      ],
    };
  },
  methods: {
    hiringBtnHandler() {
      this.$vuetify.goTo('#job');
      this.$gtag.event('event', { value: 'Job from Team section' });
    },
  },
};
</script>

<style lang="scss" scoped>
.team {
    width: 100%;
    padding: 70px 0;
    background-color: #FAF1E7;
    .members {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 20px;
    }
    .avatar {
        margin-bottom: 10px;
    }
    .title-row {
        position:relative;
    }
    .section-title {
        font-size: 83px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 30px;
        width: 100%;
        @media screen and (max-width: 790px) {
          position: relative;
          font-size: 32px;
        }
        @media screen and (max-width: 600px){
          text-align: center;
          position: relative;
          margin-top: 0;
        }
    }
     .member-name {
        font-size: 20px;
        margin-bottom: 0;
        font-weight: 600;
     }
    .member-position {
        font-size: 16px;
        margin-bottom: 0;
        color: #666666;
        font-weight: 500;
    }
    .social-btn {
        margin: 0 3px;
    }
    .hiring-btn {
        font-size: 16px;
        letter-spacing: 0px;
        font-weight: 600;
        color: #CB2A76;
    }
}
</style>
