/* eslint-disable no-param-reassign */
export default function animate(word, slogan) {
  const currentSlogan = [];
  slogan.innerHTML = '';
  for (let i = 0; i < word.length; i += 1) {
    const char = document.createElement('span');
    char.classList.add('nbr');
    char.innerText = 0;
    slogan.appendChild(char);
    currentSlogan.push(char);
  }
  const iterations = Math.round((2 / word.length) * 20);
  let lettersCount = word.length;
  let it = null;
  const timer = 20;
  currentSlogan.forEach((item) => {
    item.setAttribute('data-number', 0);
    item.setAttribute('ready', 0);
  });

  function iteration() {
    const index = Math.round(Math.random() * (word.length - 1));
    const iterationCount = +currentSlogan[index].getAttribute('data-number');
    const isReady = +currentSlogan[index].getAttribute('ready');
    if (iterationCount < iterations && isReady === 0) {
      let data = iterationCount;
      currentSlogan[index].setAttribute('data-number', data += 1);
      currentSlogan[index].innerText = Math.round(Math.random() * 9);
    } else if (iterationCount >= iterations && isReady === 0) {
      currentSlogan[index].innerText = word[index];
      lettersCount -= 1;
      currentSlogan[index].setAttribute('ready', 1);
    }
    lettersCount = 0;
  }

  if (!it && lettersCount > 0) {
    it = setInterval(iteration, timer);
  } else if (lettersCount <= 0) {
    clearInterval(it);
  }
}
